import styled from 'styled-components';

import media from 'styles/media';
import colors from 'styles/colors';

export const PrimaryTitle = styled.h1`
  color: ${props => colors[props.color] || colors.black};
  font-weight: ${props => props.weight || 'bold'};
  text-align: ${props => props.align || 'center'};
  font-size: 2rem;
  line-height: 2.5rem;
  /* margin-bottom: 20px; */
  ${media.md`
  text-align: ${props => props.align || 'left'};
    font-size: 2.625rem; /* 42px */
    line-height: 3.75rem; 
    /* margin-bottom: 40px; */
  `}
`;

export const SecondaryTitle = styled.h2`
  color: ${props => colors[props.color] || colors.black};
  font-weight: ${props => props.weight || 'bold'};
  text-align: ${props => props.align || 'center'};
  font-size: 1.875rem;
  line-height: 2.5rem;
  /* margin-bottom: 40px; */
  ${media.md`
    /* margin-bottom: 60px; */
    font-size: 2.375rem; /* 38px */
    line-height: 3.125rem;
    text-align: ${props => props.desktopAlign || 'center'};
  `}
`;

export const BlockTitle = styled.h3`
  color: ${props => colors[props.color] || colors.black};
  font-weight: ${props => props.weight || 'bold'};
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  ${media.md`
    text-align: left;
    font-size: 1.875rem; /* 30px */
    line-height: 2.625rem;
  `}
`;

export const Text = styled.p`
  color: ${props => colors[props.color] || colors.black};
  font-weight: ${props => props.weight || 'normal'};
  text-align: ${props => props.mobileAlign || 'center'};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 20px;
  ${media.md`
    text-align: ${props => props.desktopAlign || 'left'};
  `}
`;

export const BigText = styled(Text)`
  ${media.md`
    font-size: 1.125rem;
  `}
`;

export const BiggerText = styled(Text)`
  color: ${props => colors[props.color] || colors.black};
  font-size: 1.125rem;
  ${media.md`
    font-size: 1.3125rem;
  `}
`;

export const ParagraphTitle = styled(Text)`
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: bold;
`;

export const ParagraphText = styled(Text)`
  margin-top: 10px;
`;

export const QuoteText = styled(Text)`
  font-style: italic;
`;
